<script>
    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';
    import { user } from '../stores/stores';
    import { initStateCursor } from '../helpers/modules'


    onMount(async() => {
        if(!$user.person || !$user.person.role) {
            navigate("/admin");
            initStateCursor();
        }
    });
</script>

{#if $user.token}
    <slot></slot>
{/if}