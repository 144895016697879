<script>
    import { onMount } from 'svelte';
    import { fly } from 'svelte/transition';
    import { Link, navigate } from 'svelte-routing';
    import { initStateCursor } from '../../helpers/modules';
    import { validationForm } from '../../helpers/modules';
    import { user } from '../../stores/stores';
    
    import api from '../../service/apiClient';
    import Input from '../../components/elements/Input.svelte';
    import InputGroup from '../../components/elements/InputGroup.svelte';
    import InputUpload from '../../components/elements/InputUpload.svelte';
    import NavLink from '../../components/NavLink.svelte';
    
    export let bounce;

    let isValid = true;
    let dataInputs = {
        "id": {
            value: '00000000-0000-0000-0000-000000000000',
            validation: {
                status: true
            }
        },
        "created" : {
            value: new Date().toISOString(),
            placeholder: "Дата",
            require: "default",
            type: 'date',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "title_ru": {
            value: '',
            placeholder: "Название RU",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "title_en": {
            value: '',
            placeholder: "Название EN",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "title_uk": {
            value: '',
            placeholder: "Название UA",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "description_ru": {
            value: '',
            placeholder: "Описание RU",
            require: "default",
            type: 'textarea',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "description_en": {
            value: '',
            placeholder: "Описание EN",
            require: "default",
            type: 'textarea',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "description_uk": {
            value: '',
            placeholder: "Описание UA",
            require: "default",
            type: 'textarea',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "type_ru": {
            value: 'Другие RU',
            placeholder: "Тип проекта",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "type_en": {
            value: 'Другие EN',
            placeholder: "Тип проекта",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "type_uk": {
            value: 'Другие UA',
            placeholder: "Тип проекта",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "city_ru": {
            value: '',
            placeholder: "Город RU",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "city_en": {
            value: '',
            placeholder: "Город EN",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "city_uk": {
            value: '',
            placeholder: "Город UA",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "square": {
            value: 0,
            placeholder: "Площадь",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "photos": {
            value: [null],
            validation: {
                status: true
            }
        },
        "members_ru": {
            value: [],
            placeholder: "Команда RU",
            require: "default",
            type: 'default',
            validation: {
                status: true,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "members_en": {
            value: [],
            placeholder: "Команда EN",
            require: "default",
            type: 'default',
            validation: {
                status: true,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "members_uk": {
            value: [],
            placeholder: "Команда UA",
            require: "default",
            type: 'default',
            validation: {
                status: true,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
    }

    const initStatePage = () => {
        window.scrollTo(0, 0);
    }

    const onImageInit = () => {
        let images = dataInputs["photos"].value;
        let length = images.length;

        if( images[length - 1] || !length ) {
            images.push(null);
        }

        dataInputs["photos"].value = images;
    }
    const onImageLoad = (value, i) => {
        let images = dataInputs["photos"].value;
            images[i] = value;

        onImageInit();
    }
    const onImageDelete = (index) => {
        let images = dataInputs["photos"].value;
            images.splice(index, 1);
        dataInputs["photos"].value = images;
    }

    const onSubmit = async (e) => {
        e.preventDefault(); 

        isValid = validationForm(dataInputs);
        if( isValid ) {
            let data = {};

            Object.entries(dataInputs).map(([key, el]) => {
                data[key] = el.value;
            });


            
            
            data['photos']  = data['photos'].filter((el) => el);
            data.title = {
                ru: data.title_ru,
                en: data.title_en,
                uk: data.title_uk
            }

            data.description = {
                ru: data.description_ru,
                en: data.description_en,
                uk: data.description_uk
            }

            data.city = {
                ru: data.city_ru,
                en: data.city_en,
                uk: data.city_uk
            }
            
            data.type = {
                ru: data.type_ru,
                en: data.type_en,
                uk: data.type_uk
            }

            let memRu = data.members_ru.map((el) => el.trim());
            let memEn = data.members_en.map((el) => el.trim());
            let memUk = data.members_uk.map((el) => el.trim());

            data.members = [];
            for (let i = 0; i < memRu.length; i++) {
                let ru = memRu[i];
                let en = memEn[i];
                let uk = memUk[i];

                data.members.push({
                    ru: ru,
                    en: en,
                    uk: uk
                });
                
            }

            console.log(data);
            await api.createProject(data, $user).then(response => {
                if(response.status == 200) {
                    navigate('/admin/projects')
                }
            });
        }
    }


    onMount(async () => {
        initStatePage();
        initStateCursor();


        if(bounce) {
            let data = (await api.getProjInfo(bounce)).project;
         
            console.log(data);

            dataInputs['id'].value = data.id;
            dataInputs['created'].value = data.created;
            dataInputs['square'].value = data.square;
            dataInputs['photos'].value = data.photos;

            
            
            dataInputs['title_ru'].value = data.title.ru;
            dataInputs['title_en'].value = data.title.en;
            dataInputs['title_uk'].value = data.title.uk;

            dataInputs['description_ru'].value = data.description.ru;
            dataInputs['description_en'].value = data.description.en;
            dataInputs['description_uk'].value = data.description.uk;

            dataInputs['type_ru'].value = data.type.ru;
            dataInputs['type_en'].value = data.type.en;
            dataInputs['type_uk'].value = data.type.uk;

            dataInputs['city_ru'].value = data.city.ru;
            dataInputs['city_en'].value = data.city.en;
            dataInputs['city_uk'].value = data.city.uk;

            dataInputs['members_ru'].value = data.members.map(x => x.ru);
            dataInputs['members_en'].value = data.members.map(x => x.en);
            dataInputs['members_uk'].value = data.members.map(x => x.uk);
            onImageInit();
        }
    })
</script>

<section class="underwrite">
    <div class="underwrite-body">
        <nav class="underwrite-panel">
            <NavLink to="/admin/projects">Проекты</NavLink>
            <NavLink to="/admin/comments">Отзывы</NavLink>
        </nav>
        <div class="underwrite-content">
            <div class="container">
                <Link to="/admin/projects" class="l-btn os-reverse">
                    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                        <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Link>
                <div class="os-margin-20"></div>

                {#if bounce}
                    <h2>Редактирование</h2>
                {:else}
                    <h2>Создание</h2>
                {/if}

                <form class:error-valid="{ !isValid }" in:fly="{{ y: 200, duration: 500 }}" on:submit={onSubmit} autocomplete="off">
                    <div class="ui-form--data">
                        <div class="col col-md-6 col-xs-12">
                            <div class="ui-form--data-content">
                                <Input element = {dataInputs["title_ru"]} />
                                <Input element = {dataInputs["title_en"]} />
                                <Input element = {dataInputs["title_uk"]} />

                                <Input element = {dataInputs["description_ru"]} />
                                <Input element = {dataInputs["description_en"]} />
                                <Input element = {dataInputs["description_uk"]} />

                                <div class="os-margin-20"></div>
                                <div class="ui-form--data">
                                    <div class="col col-md-6 col-xs-12">
                                        <Input element = {dataInputs["type_ru"]} />
                                        <Input element = {dataInputs["type_en"]} />
                                        <Input element = {dataInputs["type_uk"]} />
                                    </div>
                                    <div class="col col-md-6 col-xs-12">
                                        <Input element = {dataInputs["created"]} />
                                    </div>
                                </div>
                                
                                <Input element = {dataInputs["city_ru"]} />
                                <Input element = {dataInputs["city_en"]} />
                                <Input element = {dataInputs["city_uk"]} />
                                <Input element = {dataInputs["square"]} />
                                <InputGroup element = {dataInputs["members_ru"]} />
                                <InputGroup element = {dataInputs["members_en"]} />
                                <InputGroup element = {dataInputs["members_uk"]} />
                            </div>

                            <div class="ui-form--navigate os-desktop-visible">
                                <button class="btn btn-new">Сохранить изменения</button>
                            </div>
                        </div>
                        <div class="col col-md-6 col-xs-12">
                            <div class="row-flex">
                                {#each dataInputs["photos"].value as item, i}
                                    <div class="gallery-item">
                                        <InputUpload path = {item} onDelete={() => { onImageDelete(i) }} onCallback={(v) => { onImageLoad(v, i) }} />
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </div>
                    <div class="ui-form--navigate os-mobile-visible">
                        <button class="btn btn-new">Сохранить изменения</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div class="underwrite-navigate">
    <div>
        <Link to="/admin/projects" class="l-btn btn-icon icon-projects" />
        <p>Проекты</p>
    </div>
    <div>
        <Link to="/admin/comments" class="l-btn btn-icon icon-comments" />
        <p>Отзывы</p>
    </div>
</div>
