<script>
    import { message } from '../stores/stores.js';
    import { fade, fly } from 'svelte/transition';

    let isVisible = false;

    const unsubscribe = message.subscribe(value => {
		isVisible = value;

        setTimeout(() => {
            isVisible = false;
        }, 2000)
	});
</script>

{#if isVisible}
<div class="popup-message" in:fly="{{ y: 200, duration: 500 }}" out:fade>
    <p>Заявка успешно отправлена</p>
</div>
{/if}