import { debug } from 'svelte/internal';
import api from './apiClient';
import storage from './storageClient';

export const auth = async (token) => {
    var authToken = await api.login(token);
    storage.set('authToken', authToken);
}

export const getToken = () => storage.get('authToken');
