<script>
    import { navigate } from 'svelte-routing';
    export let path  = '';
    export let style = '';
    
    const handlerClick = () => {
        navigate(path);
        window.location.reload();
    }
</script>

<div class={style} on:click={handlerClick}>
    <slot></slot>
</div>