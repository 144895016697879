<script>
    import { fade, fly } from 'svelte/transition';
    import { loading } from '../stores/stores.js';

    let isLoading = true;
    let setLoading = {
        loading: {
            func: () => {
                isLoading = false
                loading.update(n => n = false);
            },
            time: 1500
        }
    }

    const unsubscribe = loading.subscribe(value => {
		isLoading = value;
	});

    const sleepLoading = (func, ms) => (
        new Promise(resolve => setTimeout(() => {
            func();
            return resolve()
        }, ms))
    )

    async function updateLoading ({ func, time }) {
        await sleepLoading(func, time);
    }
    async function initLoading(){
        await updateLoading(setLoading.loading);
    }

    initLoading();
</script>

{#if isLoading}
<div class="loader-wrapper" in:fly="{{ y: 200, duration: 2000 }}" out:fade>
    <div class="loader">
        <div class="segment-loader">
            <div class="segment one"></div>
        </div>
        <div class="segment-loader">
            <div class="segment two"></div>
        </div>
        <div class="segment-loader">
            <div class="segment three"></div>
        </div>
        <div class="segment-loader">
            <div class="segment four"></div>
        </div>
    </div>
</div>
{/if}