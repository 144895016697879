<script>
    import { onMount } from "svelte";
    import { Link } from "svelte-routing";
    import { user } from "../../stores/stores";
    import { initStateCursor } from "../../helpers/modules";
    import NavLink from "../../components/NavLink.svelte";
    import Button from "../../components/Button.svelte";
    import ButtonIcon from "../../components/ButtonIcon.svelte";
    import storage from "../../service/storageClient";

    const initStatePage = () => {
        window.scrollTo(0, 0);
    };

    const initImagePage = (path) => {
        return path;
    };

    const signOut = () => {
        storage.set("authToken", null);
        window.location.reload();
    };

    onMount(async () => {
        initStatePage();
        initStateCursor();
    });
</script>

<section class="underwrite">
    <div class="underwrite-body">
        {#if $user.person && $user.person.role}
            <nav class="underwrite-panel">
                <NavLink to="/admin/projects" class="icon icon-home"
                    >Проекты</NavLink
                >
                <NavLink to="/admin/comments" class="icon icon-home"
                    >Отзывы</NavLink
                >
                <button class="btn btn-new" on:click={signOut}>Sign Out</button>
            </nav>
            <div class="underwrite-content">
                <h2>Вы успешно авторизованы</h2>
                <div class="personal-profile">
                    <img src={initImagePage($user.person.avatar)} alt="" />
                    <div>
                        <h2>{$user.person.full_name}</h2>
                        <p>Вы можете редактировать данные</p>
                    </div>
                </div>
            </div>
        {:else}
            <div class="underwrite-content">
                <div class="os-text-center">
                    <p>Sign in</p>
                    <Button text="Авторизация через Google" type="login" verification="true" />
                </div>
            </div>
        {/if}
    </div>
</section>

<div class="underwrite-navigate">
    <div>
        <Link to="/admin/projects" class="l-btn btn-icon icon-projects" />
        <p>Проекты</p>
    </div>
    <div>
        <Link to="/admin/comments" class="l-btn btn-icon icon-comments" />
        <p>Отзывы</p>
    </div>
</div>
