<script>
    import { onMount } from 'svelte';
    import { Link, navigate } from 'svelte-routing';
    import { fly } from 'svelte/transition';
    import { initStateCursor, validationForm } from '../../helpers/modules';
    import { initDateFormat} from '../../helpers/dateFormat';
    import { user } from '../../stores/stores';
    
    import api from '../../service/apiClient';
    import Input from '../../components/elements/Input.svelte';
    import InputUpload from '../../components/elements/InputUpload.svelte';
    import NavLink from '../../components/NavLink.svelte';
    
    export let bounce;

    let isValid = true;
    let dataInputs = {
        "id": {
            value: '00000000-0000-0000-0000-000000000000',
            validation: {
                status: true
            }
        },
        "avatarUrl": {
            value: null,
            validation: {
                status: true
            }
        },
        "created" : {
            value: new Date().toISOString(),
            placeholder: "Дата",
            require: "default",
            type: 'date',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "fullName": {
            value: '',
            placeholder: "Имя",
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "text": {
            value: '',
            placeholder: "Ваш коментарий",
            require: "default",
            type: 'textarea',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        }
    }

    const initStatePage = () => {
        window.scrollTo(0, 0);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        isValid = validationForm(dataInputs);
        if( isValid ) {
            let data = {};

            Object.entries(dataInputs).map(([key, el]) => {
                data[key] = el.value;
            });

            let response = bounce ?
                await api.editComment(data, $user):
                await api.createComment(data, $user);

            if(response.status == 200) {
                navigate('/admin/comments')
            }
        }
    }

    onMount(async () => {
        initStatePage();
        initStateCursor();
        initDateFormat();

        if(bounce) {
            await api.getCommentAll().then((response) => {
                let data = response.filter(d => d.id == bounce);

                Object.entries(data[0]).map(([key, val]) => {
                    dataInputs[key].value = val;
                })
            });
        }
    })
</script>

<section class="underwrite">
    <div class="underwrite-body">
        <nav class="underwrite-panel">
            <NavLink to="/admin/projects">Проекты</NavLink>
            <NavLink to="/admin/comments">Отзывы</NavLink>
        </nav>
        <div class="underwrite-content">
            <div class="container">
                <Link to="/admin/comments" class="l-btn os-reverse">
                    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                        <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Link>
                <div class="os-margin-20"></div>

                {#if bounce}
                    <h2>Редактирование</h2>
                {:else}
                    <h2>Создание</h2>
                {/if}

                <form class:error-valid="{ !isValid }" in:fly="{{ y: 200, duration: 500 }}" on:submit={onSubmit} autocomplete="off">
                    <div class="ui-form--data">
                        <div class="col" style="max-width: 1000px">
                            <InputUpload path = {dataInputs["avatarUrl"].value} onCallback={(v) => { dataInputs["avatarUrl"].value = v }} />
                            <div class="os-margin-20"></div>
                            <div class="ui-form--data-content">
                                <Input element = {dataInputs["created"]} />
                                <Input element = {dataInputs["fullName"]} />
                                <Input element = {dataInputs["text"]} />
                            </div>
                        </div>
                    </div>
                    <div class="ui-form--navigate">
                        <button class="btn btn-new">Сохранить</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div class="underwrite-navigate">
    <div>
        <Link to="/admin/projects" class="l-btn btn-icon icon-projects" />
        <p>Проекты</p>
    </div>
    <div>
        <Link to="/admin/comments" class="l-btn btn-icon icon-comments" />
        <p>Отзывы</p>
    </div>
</div>
