<script>
    import { onMount } from 'svelte';
    import { Link } from "svelte-routing"
    import { 
        initStateCursor,
        getDateYear,
        firstProjImage
    } from '../../helpers/modules';
    import { user, remove_project } from '../../stores/stores';
    import api from '../../service/apiClient';
    import NavLink from '../../components/NavLink.svelte';
    import ButtonIcon from '../../components/ButtonIcon.svelte';
    
    let dataProjects = [];
    let targetElement = null;

    const initStatePage = () => {
        window.scrollTo(0, 0);
    }

    const handleTarget = (element) => {
        targetElement = element;
    }

    const handleUIRemoving = () => {
        dataProjects = dataProjects.filter(order => order.id != targetElement.id);

        targetElement = null;
        remove_project.update(v => v = false);
    }

    const unsubscribeRemove = remove_project.subscribe(value => {
        if(value && targetElement) {
            api.removeProject(targetElement.id, $user)
                .then(result => {
                    if(result.status == 200) {
                        handleUIRemoving();
                    }
                })
        }
	});

    onMount(async () => {
        initStatePage();
        initStateCursor();

        dataProjects = await api.getAll();
    });
</script>

<section class="underwrite">
    <div class="underwrite-body">
        <nav class="underwrite-panel">
            <NavLink to="/admin/projects" class="icon icon-home">Проекты</NavLink>
            <!-- <NavLink to="/admin/comments" class="icon icon-home">Отзывы</NavLink> -->
        </nav>
        <div class="underwrite-content">
            <h2>Наши проекты</h2>

            <div class="personal-grid">
                <div class="row-flex">
                    {#each dataProjects as item}
                        <div class="col-md-4 col-xs-12">
                            <div class="grid-item">
                                <div class="grid-item__content">
                                    <div class="title">{item.title.ru}</div>
                                    <div class="description">{item.city.ru}, { getDateYear(item.created) }</div>
                                </div>
                                <img src="{firstProjImage(item)}" alt="">
                                <div class="grid-item__navigate os-justify-flex">
                                    <Link class="l-btn btn-icon icon-edit" to="/admin/projects-edit/{item.id}"/>
                                    <ButtonIcon type="project" method="remove"  alt="Удалить" element={ item } handleCallback={ handleTarget } />
                                </div>
                            </div>
                        </div>
                    {/each}
                </div>
                <div class="os-margin-20"></div>
                <Link class="btn btn-new" to="/admin/projects-edit/">Создать новый проект</Link>
            </div>
        </div>
    </div>
</section>

<div class="underwrite-navigate">
    <div>
        <Link to="/admin/projects" class="l-btn btn-icon icon-projects" />
        <p>Проекты</p>
    </div>
    <div>
        <Link to="/admin/comments" class="l-btn btn-icon icon-comments" />
        <p>Отзывы</p>
    </div>
</div>
