export function ripplesEffect (node, key) {
    // let sprite = node.querySelector("img").getAttribute("src");


    const handleHover = (event) => {
        CanvasWaterEffect.moveSlider(key);

        // let coords = event.target.getBoundingClientRect();
        // console.log(coords);

        if (document.getElementById('canvasHome')) {
            let elem = document.getElementById('canvasHome');
            node.insertBefore(elem, node.children[0]);
            // elem.setAttribute(
            //     "style",
            //     `width: ${coords.width}px; height: ${coords.height}px; top:${coords.top}px; left:${coords.left}px`
            // )
        }
    }

    node.addEventListener('mouseover', handleHover);

    // setInterval(() => {
    //     CanvasWaterEffect.moveSlider(0);
    // }, 2000)

    return {
        destroy () {
            node.removeEventListener('hover', handleHover);
        }
    }
}
