const initial = () => {
    window.CanvasSlideshow = function(options) {
        var that = this;
    
        options = options || {};
        options.stageWidth = options.hasOwnProperty('stageWidth') ? options.stageWidth : 1920;
        options.stageHeight = options.hasOwnProperty('stageHeight') ? options.stageHeight : 1080;
        options.pixiSprites = options.hasOwnProperty('sprites') ? options.sprites : [];
        options.centerSprites = options.hasOwnProperty('centerSprites') ? options.centerSprites : false;
        options.texts = options.hasOwnProperty('texts') ? options.texts : [];
        options.autoPlay = options.hasOwnProperty('autoPlay') ? options.autoPlay : true;
        options.autoPlaySpeed = options.hasOwnProperty('autoPlaySpeed') ? options.autoPlaySpeed : [10, 3];
        options.fullScreen = options.hasOwnProperty('fullScreen') ? options.fullScreen : true;
        options.displaceScale = options.hasOwnProperty('displaceScale') ? options.displaceScale : [200, 70];
        options.displacementImage = options.hasOwnProperty('displacementImage') ? options.displacementImage : '';
        options.navElement = options.hasOwnProperty('navElement') ? options.navElement : document.querySelectorAll('.scene-nav');
        options.displaceAutoFit = options.hasOwnProperty('displaceAutoFit') ? options.displaceAutoFit : false;
        options.wacky = options.hasOwnProperty('wacky') ? options.wacky : false;
        options.interactive = options.hasOwnProperty('interactive') ? options.interactive : false;
        options.interactionEvent = options.hasOwnProperty('interactionEvent') ? options.interactionEvent : '';
        options.displaceScaleTo = (options.autoPlay === false) ? [0, 0] : [20, 20];
        options.textColor = options.hasOwnProperty('textColor') ? options.textColor : '#fff';
        options.displacementCenter = options.hasOwnProperty('displacementCenter') ? options.displacementCenter : false;
        options.dispatchPointerOver = options.hasOwnProperty('dispatchPointerOver') ? options.dispatchPointerOver : false;
        options.node = options.hasOwnProperty('node') ? options.node : document.body;

        var renderer = new PIXI.autoDetectRenderer(options.stageWidth,options.stageHeight,{
            transparent: true
        });
        this.render = renderer;
    
        var stage = new PIXI.Container();
        var slidesContainer = new PIXI.Container();
        var displacementSprite = new PIXI.Sprite.fromImage(options.displacementImage);
        var displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        var style = new PIXI.TextStyle({
            fill: options.textColor,
            wordWrap: true,
            wordWrapWidth: 400,
            fontSize: 36
        });
    
        this.initPixi = function() {
            // if (document.getElementById('canvasHome')) {
            //     document.getElementById('canvasHome').remove();
            // }
            options.node.appendChild(renderer.view);
    
            stage.addChild(slidesContainer);
            stage.interactive = true;
    
            if (options.fullScreen === true) {
                renderer.view.style.objectFit = 'cover';
    
                renderer.view.style.width = 100 + '%';
                renderer.view.style.height = 100 + '%';
                renderer.view.style.top = '50%';
                renderer.view.style.left = '50%';
                renderer.view.style.webkitTransform = 'translate( -50%, -50% )';
                renderer.view.style.transform = 'translate( -50%, -50% ';
    
                renderer.view.style.borderRadius = (options.hasOwnProperty('menu') || options.hasOwnProperty('about')) ? "50%" : '0';
            } else {
                renderer.view.style.maxWidth = '100%';
                renderer.view.style.top = '50%';
                renderer.view.style.left = '50%';
                renderer.view.style.webkitTransform = 'translate( -50%, -50% )';
                renderer.view.style.transform = 'translate( -50%, -50% )';
            }
    
            if (options.hasOwnProperty('id')) {
                renderer.view.id = options.id;
            }
            displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
            stage.filters = [displacementFilter];
            if (options.autoPlay === false) {
                displacementFilter.scale.x = 0;
                displacementFilter.scale.y = 0;
            }
            if (options.wacky === true) {
                displacementSprite.anchor.set(0.5);
                displacementSprite.x = renderer.width / 2;
                displacementSprite.y = renderer.height / 2;
            }
            displacementSprite.scale.x = 2;
            displacementSprite.scale.y = 2;
            displacementFilter.autoFit = options.displaceAutoFit;
            stage.addChild(displacementSprite);
        };
    
        this.loadPixiSprites = function(sprites) {
            var rSprites = options.sprites;
            for (var i = 0; i < rSprites.length; i++) {
                var texture = new PIXI.Texture.fromImage(sprites[i]);
                var image = new PIXI.Sprite(texture);
                if (options.centerSprites === true) {
                    image.anchor.set(0.5);
                    image.x = renderer.width / 2;
                    image.y = renderer.height / 2;
                }
                if (i !== 0) {
                    TweenMax.set(image, {
                        alpha: 0
                    });
                }
                image.width = 1920 * 1.1;
                image.height = 1080 * 1.1;
                slidesContainer.addChild(image);
            }
        };
    
        if (options.autoPlay === true) {
            var ticker = new PIXI.ticker.Ticker();
            ticker.autoStart = options.autoPlay;
            ticker.add(function(delta) {
                displacementSprite.x += options.autoPlaySpeed[0] * delta;
                displacementSprite.y += options.autoPlaySpeed[1];
                renderer.render(stage);
            });
        } else {
            var render = new PIXI.ticker.Ticker();
            render.autoStart = true;
            render.add(function(delta) {
                renderer.render(stage);
            });
        }
        this.isPlaying = false;
        var slideImages = slidesContainer.children;
    
        this.moveSlider = function(newIndex) {
            console.log(1)
            that.isPlaying = true;
            var baseTimeline = new TimelineMax({
                onComplete: function() {
                    that.currentIndex = newIndex;
                    that.isPlaying = false;
                    if (options.wacky === true) {
                        displacementSprite.scale.set(1);
                    }
                    console.log(111);
                },
                onUpdate: function() {
                    if (options.wacky === true) {
                        if (options.hasOwnProperty('menu')) {
                            displacementSprite.rotation += baseTimeline.progress() * 0.06;
                            displacementSprite.scale.set(baseTimeline.progress() * 9);
                        } else {
                            displacementSprite.rotation += baseTimeline.progress() * 0.02;
                            displacementSprite.scale.set(baseTimeline.progress() * 3);
                        }
                    }
                }
            });
            baseTimeline.clear();
            if (baseTimeline.isActive()) {
                return;
            }
            baseTimeline.to(displacementFilter.scale, 1, {
                x: options.displaceScale[0],
                y: options.displaceScale[1],
                ease: Power1.easeOut
            }).to(slideImages[that.currentIndex], 0.5, {
                alpha: 0,
                ease: Power2.easeOut
            }, 0.2).to(slideImages[newIndex], 0.5, {
                alpha: 1,
                ease: Power2.easeOut
            }, 0.3).to(displacementFilter.scale, 1, {
                x: options.displaceScaleTo[0],
                y: options.displaceScaleTo[1],
                ease: Power2.easeOut
            }, 0.3);
        }
    
        this.init = function() {
            that.initPixi();
            that.loadPixiSprites(options.pixiSprites);
        }
    
        if (options.interactive === true) {
            var rafID, mouseX, mouseY;
            slidesContainer.interactive = true;
            slidesContainer.buttonMode = true;
            if (options.interactionEvent === 'hover' || options.interactionEvent === 'both') {
                slidesContainer.pointerover = function(mouseData) {
                    mouseX = mouseData.data.global.x;
                    mouseY = mouseData.data.global.y;
                    TweenMax.to(displacementFilter.scale, 1, {
                        x: "+=" + Math.sin(mouseX) * 100 + "",
                        y: "+=" + Math.cos(mouseY) * 100 + ""
                    });
                }
                ;
                slidesContainer.pointerout = function(mouseData) {
                    TweenMax.to(displacementFilter.scale, 1, {
                        x: 0,
                        y: 0
                    });
                    cancelAnimationFrame(rafID);
                }
                ;
            }
            if (options.interactionEvent === 'click' || options.interactionEvent === 'both') {
                slidesContainer.pointerup = function(mouseData) {
                    if (options.dispatchPointerOver === true) {
                        TweenMax.to(displacementFilter.scale, 1, {
                            x: 0,
                            y: 0,
                            onComplete: function() {
                                TweenMax.to(displacementFilter.scale, 1, {
                                    x: 20,
                                    y: 20
                                });
                            }
                        });
                    } else {
                        TweenMax.to(displacementFilter.scale, 1, {
                            x: 0,
                            y: 0
                        });
                        cancelAnimationFrame(rafID);
                    }
                }
                ;
                slidesContainer.pointerdown = function(mouseData) {
                    mouseX = mouseData.data.global.x;
                    mouseY = mouseData.data.global.y;
                    TweenMax.to(displacementFilter.scale, 1, {
                        x: "+=" + Math.sin(mouseX) * 1200 + "",
                        y: "+=" + Math.cos(mouseY) * 200 + ""
                    });
                }
                ;
                slidesContainer.pointerout = function(mouseData) {
                    if (options.dispatchPointerOver === true) {
                        TweenMax.to(displacementFilter.scale, 1, {
                            x: 0,
                            y: 0,
                            onComplete: function() {
                                TweenMax.to(displacementFilter.scale, 1, {
                                    x: 20,
                                    y: 20
                                });
                            }
                        });
                    } else {
                        TweenMax.to(displacementFilter.scale, 1, {
                            x: 0,
                            y: 0
                        });
                        cancelAnimationFrame(rafID);
                    }
                }
                ;
            }
        }
    
        if (options.displacementCenter === true) {
            displacementSprite.anchor.set(0.5);
            displacementSprite.x = renderer.view.width / 2;
            displacementSprite.y = renderer.view.height / 2;
        }
        this.init();
    }
}

export default {
    initial
}