<script>
    import Input from './elements/Input.svelte';
    import api from '../service/apiClient';
    import { message, comment, user } from '../stores/stores.js';
    import { fade, fly } from 'svelte/transition';
    import { validationForm } from '../helpers/modules';
    import { language} from '../stores/stores'
    import trans from '../helpers/translates'

    let isValid = true;
    let inputs = {
        "comment": {
            value: '',
            placeholder: trans.YOUR_COMMENT[$language],
            require: "default",
            type: 'textarea',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        }
    }

    const onClosed = () => {
        comment.update(v => v = false);
    }

    const onCleaned = () => {
        inputs.map(item => {
            item.value = '';
            item.isValid = false;
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        isValid = validationForm(inputs);
        if( isValid ) {
            let comment = inputs["comment"].value;

            await api.makeComment($user, comment)
                .then(result => {
                    if(result.status == 200) {
                        message.update(v => v = true);
                        onClosed();
                        onCleaned();
                    }
                })
        }
    }
</script>

{#if $comment && $user.token}
<div class="popup-comment" in:fly="{{ duration: 500 }}" out:fade>
    <div class="wrapper">
        <h1>ВАШ ОТЗЫВ</h1>

        <form class="{ isValid ? '': 'error-valid' }" on:submit={onSubmit} autocomplete="off">
            <div class="ui-form--data row-flex">
                <div class="col-md-12 col">
                    <Input element={inputs["comment"]} />
                </div>
            </div>

            <div class="ui-form--navigate">
                <button class="btn btn-new">Оставить отзыв</button>
            </div>
        </form>
        
        <div class="l-btn btn-close-popup" on:click={onClosed}></div>
    </div>
</div>
{/if}