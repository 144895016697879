import { user } from '../stores/stores.js';

let userJWT = {};

const get = (name) => 
    localStorage.getItem(name)
        ? JSON.parse(localStorage.getItem(name)) : null;

const set = (name, token) => {
    localStorage.setItem(name, JSON.stringify(token));
    init();
}

const init = () => {
    userJWT.token  = get('authToken') || null;
    userJWT.person = is_initial_person();

    user.update(v => v = userJWT);
}

const decodeAuthJwt = (token) => {
    let jwtData = userJWT.token.split('.')[1];
    let decodedJwtJsonData = decodeURIComponent(escape(window.atob(jwtData)));
    let decodedJwtData = JSON.parse(decodedJwtJsonData);

    return {
        role:       decodedJwtData.user_role,
        full_name:  decodedJwtData.user_full_name,
    }
}


const is_initial_person = () => {
    if(userJWT.token) {
        let jwtData = userJWT.token.split('.')[1];
        let decodedJwtJsonData = decodeURIComponent(escape(window.atob(jwtData)));
        let decodedJwtData = JSON.parse(decodedJwtJsonData);

        return {
            role:       decodedJwtData.user_role == 'admin',
            avatar:     decodedJwtData.user_avatar,
            full_name:  decodedJwtData.user_full_name,

        }
    }

    return null;
}


export default {
    get,
    set,
    init
}