//const host = "https://api-dev.udr-group.com" // dev
const host = "https://api.udr-group.com.ua" // prod


const login = (token) => fetch(`${host}/api/contact/login`, {
    method: 'POST', 
    body: JSON.stringify({tokenId: token}),
    headers: {
        'Content-Type': 'application/json'
    }
}).then(s=> s.text())


const getAll = async () => {
    let data = await fetch(`${host}/api/Project/all`, { method: 'POST' }).then(s=> s.json())
    return data
}

const makeApplication = (name, phone, email, comment) => fetch(`${host}/api/Contact/make-application`, { 
    method: 'POST',
    body: JSON.stringify({
            name: name,
            phone: phone,
            email: email,
            comment: comment
    }),
    headers: {
        'Content-Type': 'application/json'
    }
});


const getProjInfo = (id) => fetch(`${host}/api/Project/info?projId=${id}`, { 
    headers: {
        'Content-Type': 'application/json'
    },
}).then(s=> s.json())


const getCommentAll = () => fetch(`${host}/api/Contact/comment-list`, { 
    headers: {
        'Content-Type': 'application/json'
    },
}).then(s=> s.json())


const makeComment = (user, comment) => fetch(`${host}/api/Contact/comment-client`, { 
    method: 'POST',
    body: JSON.stringify({
        text: comment
    }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const removeComment = (id, user) => fetch(`${host}/api/Contact/comment-delete`, { 
    method: 'POST',
    body: JSON.stringify({
        id: id
    }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const editComment = (data, user) => fetch(`${host}/api/Contact/comment-edit`, { 
    method: 'POST',
    body: JSON.stringify({ ...data }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const createComment = (data, user) => fetch(`${host}/api/Contact/comment-admin`, { 
    method: 'POST',
    body: JSON.stringify({ ...data }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const removeProject = (id, user) => fetch(`${host}/api/Project/delete`, { 
    method: 'POST',
    body: JSON.stringify({
        id: id
    }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const createProject = (data, user) => fetch(`${host}/api/Project/create-or-update`, { 
    method: 'POST',
    body: JSON.stringify({ ...data }),
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
    }
});

const uploadPhoto = (file, user) => fetch(`${host}/api/Project/upload-photo`, { 
    method: 'POST',
    body: file,
    headers: {
        'Authorization': `Bearer ${user.token}`
    }
}).then(s=> s.json());

export default {
    host,
    getAll,
    makeApplication,
    getProjInfo,
    getCommentAll,
    makeComment,
    removeComment,
    editComment,
    createComment,
    removeProject,
    createProject,
    uploadPhoto,
    login
}
