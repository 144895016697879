<script>
    import { onMount } from 'svelte';
    import { links } from "svelte-routing";
    import { 
        initStateCursor, 
        initStateAnimation, 
        removeClass, 
        getDateYear, 
        random_phase, 
        firstProjImage,
        groupBy
    } from '../../helpers/modules';
    import Button from '../../components/Button.svelte';
    import Footer from '../../components/Footer.svelte';
    import api from '../../service/apiClient';
    import {language} from '../../stores/stores'
    import trans from '../../helpers/translates'


    let filter = function(){}
    let filterTab = 0;
    let projects = [];
    $: tabs = getTabs(projects, $language);

    const initStatePage = () => {
        removeClass('page-main', document.body);
        window.scrollTo(0, 0);
    }
    const initIsotope = () => {
        let elem = document.querySelector('.grid');
        let iso = new Isotope( elem, {
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
                columnWidth: 1
            },
            filter: '.grid0'
        });
        
        filter = (index) => {
            iso.arrange({
                filter: `.grid${index}`
            });

            filterTab = index;
        }

        initStateAnimation();
    }
    
    const getTabs = (projects, lang) => {
        var tabs = groupBy(projects, x => x.type[lang]);
        console.log(tabs);
        
        return tabs;
    };

    onMount(async() => {
        initStateCursor();
        initStatePage();

        projects = await api.getAll();
        setTimeout(initIsotope, 200);
    });
</script>
  
<section class="projects">
    <div class="mouse m-hide"><span></span></div>

    <div class="container">
        <div data-anim="fadeInTop" data-anim-used="false">
            <h1>{trans.OUR_PROJECTS[$language]}</h1>

            <div class="ui-filter">
                {#each Object.keys(tabs) as tabName, i}
                    <div class="ui-filter--item { filterTab == i ? 'active': '' }" on:click="{ filter(i) }">
                        <span>{tabName}</span>
                    </div>
                {/each}
            </div>
        </div>

        <div class="grid">
            {#each Object.entries(tabs) as [name, projects], i}
                <div>
                    {#each projects as item}
                        <div class="grid-item grid{i}" data-anim="fadeScale" data-anim-used="false">
                            <img src="{firstProjImage(item)}" alt="">
                            <a href={"/projects/" + item.id } class="grid-item--info" use:links>
                                <p>{item.title[$language]}</p>
                                <span>{item.city[$language]}, { getDateYear(item.created) }</span>
                            </a>
                        </div>
                    {/each}
                </div>
            {/each}
        </div>
    </div>
</section>

<section class="request">
    <div class="container os-visible">
        <div class="wrapper">
            <div class="request-wrapper">
                <div class="request-title">
                    {trans.MODERN_RENOVATION[$language]}
                </div>
                <p>{random_phase()[$language]}</p>
                <br>
                <Button text="{trans.SUBMIT_APPLICATION[$language]}" type="order" />
            </div>
            <div class="request-image">
                <img src="./images/request-image.png" alt="">
            </div>
        </div>
    </div>
</section>

<!-- <div class="show-scroll animate"></div> -->


  
<Footer />
