import { writable } from 'svelte/store';

export const order = writable(false);
export const loading = writable(true);
export const message = writable(false);
export const comment = writable(false);
export const user = writable(false);
export const confirm = writable(false);
export const remove_comment = writable(false);
export const remove_project = writable(false);
export const edit_comment = writable(false);
export const edit_project = writable(false);
export const language = writable("uk");

