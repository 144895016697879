<script>
    export let title = '';
    import { slide } from 'svelte/transition';

    let isActive = false;

    const handleToggleActive = () => {
        isActive = !isActive;
    }
</script>


<div class="os-accordion { isActive ? 'opened': '' }">
    <div class="os-accordion__head" on:click={handleToggleActive}>
        <span>{ title }</span>
    </div>
    {#if isActive}
    <div class="os-accordion__content" transition:slide>
        <div class="os-inline-block">
            <slot></slot>
        </div>
    </div>
    {/if}
</div>