<script>
    export let alt = '';
    export let type;
    export let method;
    export let element;
    export let handleCallback = function() {};

    import { confirm } from '../stores/stores';

    const handleClick = () => {
        switch (method) {
            case 'remove':
                confirm.update(v => v = {
                    status: true,
                    type: type
                });
                break;
        }
        handleCallback(element);
    }
</script>

<button class="l-btn btn-icon icon-{ method }" { alt } on:click="{ handleClick }"></button>