const translates = {
    MAIN_PHASE: {
        ru: "создаём дома, в которых хочется жить",
        uk: "Створюємо простір Вашої мрії",
        en: "We create the space of your dreams"
    },
    MAIN_PHASE_2: {
        ru: "Все что мы делаем, делаем для вас. Приступая к разработке нового проекта или внося незначительные изменения в проект в ходе авторского надзора, мы неизменно стремимся сделать вашу жизнь удобней. В разделе наших проектов вы можете посмотреть различные фото дизайна интерьера.",
        uk: "Все, що ми робимо, ми робимо для Вас. Починаючи розробку нового проекту або вносячи незначні зміни в проект під час архітектурного нагляду, ми незмінно прагнемо зробити ваше життя зручнішим. У розділі наших проєктів Ви можете побачити різноманітні фотографії дизайну інтер’єру.",
        en: "Everything we do, we do for you. Whether we start developing a new project or make minor changes to the project during architectural supervision, we always strive to make your life more comfortable. In our projects section, you can see a variety of interior design photos."
    },
    SUBMIT_APPLICATION:{
        ru: "Оставить заявку",
        uk: "Залишити заявку",
        en: "Submit application"
    },
    MAIN: {
        ru: "Главная",
        uk: "Головна",
        en: "Main"
    },
    PROJECTS: {
        ru: "Проекты",
        uk: "Проекти",
        en: "Projects"
    },
    CONTACTS: {
        ru: "Контакты",
        uk: "Контакти",
        en: "Contacts"
    },
    OUR_PROJECTS: {
        ru: "Наши проекты",
        uk: "Наші проекти",
        en: "Our projects"
    },
    YOUR_COMMENT: {
        ru: "Ваш комментарий",
        uk: "Ваш коментар",
        en: "Your comment"
    },
    MODERN_RENOVATION: {
        ru: "Современный ремонт",
        uk: "Cучасний ремонт",
        en: "Modern renovation"
    },
    PROMO_PHASE_1: {
        ru: "Ваш уникальный интерьер с полным сопровождением и ремонтом",
        uk: "Ваш унікальний інтер'єр з повним супроводом і ремонтом",
        en: "Your unique interior with full maintenance and repair"
    },
    PROMO_PHASE_2: {
        ru: "Кому доверить проект по ремонту квартиры в премиальном сегменте?",
        uk: "Кому довірити проект по ремонту квартири в преміальному сегменті?",
        en: "Who should be entrusted with a project for the renovation of an apartment in the premium segment?"
    },
    PROMO_PHASE_3: {
        ru: "Сметы могут быть прозрачными, и мы готовы сделать их такими для вас",
        uk: "Кошториси можуть бути прозорими, і ми готові зробити їх такими для вас",
        en: "Estimates can be transparent, and we are ready to make them that way for you"
    },
    LIKE_PROJECT: {
        ru: "Понравился проект?",
        uk: "Сподобався проект?",
        en: "Did you like the project?"
    },
    TEAM: {
        ru: "Команда",
        uk: "Команда",
        en: "Team"
    },
    OUR_TEAM: {
        ru: "Команда",
        uk: "Наша Команда",
        en: "Our Team"
    },
    NEXT_PROJECT: {
        ru: "Следующий проект",
        uk: "Наступный проект",
        en: "Next project"
    },
    OUR_ADDRESS: {
        ru: "Наш адрес",
        uk: "Наша адреса",
        en: "Our address"
    },
    OUR_ADDRESS_VALUE: {
        ru: "Наш адрес",
        uk: "вулиця Святослава Хороброго, 1, офіс 324, Київ, 03151",
        en: "Svyatoslava Horobrogo street, 1, офіс 324, Київ, 03151"
    },
    WORK_TIME: {
        ru: "Время работы",
        uk: "Робочi години",
        en: "Work time"
    },
    WORK_WEEK_DAYS: {
        ru: "ПН-ПТ",
        uk: "ПН-ПТ",
        en: "Monday-Friday"
    },
    CALL_US: {
        ru: "Позвоните нам",
        uk: "Зателефонуйте нам",
        en: "Call us"
    },
    NAME: {
        ru: "Имя",
        uk: "Iм'я",
        en: "Name"
    },
    EMAIL: {
        ru: "E-mail",
        uk: "E-mail",
        en: "E-mail"
    },
    PHONE: {
        ru: "Телефон",
        uk: "Телефон",
        en: "Phone"
    },
    ACCEPT_POLICY: {
        ru: "Я принимаю политику конфеденциальности",
        uk: "Я приймаю політику конфіденційностi",
        en: "I accept the privacy policy"
    },
    ABOUT_US: {
        ru: "О нас",
        uk: "Про нас",
        en: "About us"
    },
    ABOUT_US_VALUE: {
        ru: "О нас",
        uk: "Ми, компанія UDR Group, яка створює індивідуальний дизайн інтер'єру та реалізує ремонт квартир, приватних будинків, комерційної нерухомості під ключ у м. Київ.<br />Наші спеціалісти мають досвід ремонтних робіт та дизайну інтер'єру більше 10 років. Зусиллями наших спеціалістів було виконано понад 100 проєктів, що дозволило нам стати надійною компанією, якій довіряють.<br /> Ми гарантуємо сумлінне обслуговування і персональний підхід до кожного.",
        en: "We are UDR Group, a company that creates individual interior design and implements turnkey repair of apartments, private houses, and commercial properties in Kyiv. Our specialists have over 10 years of experience in repair work and interior design. Through the efforts of our professionals, we have completed more than 100 projects, which has made us a reliable company that people trust.<br />We guarantee conscientious service and a personalized approach to each client."
    },
    ABOUT_TITLE: {
        ru: "...",
        uk: "ДІМ - ВАШ ІДЕАЛЬНИЙ ВСЕСВІТ",
        en: "HOME - YOUR IDEAL UNIVERSE"
    },
    TEAM_1_NAME: {
        ru: "...",
        uk: "Світлана Сінкевич",
        en: "Svitlana Sinkevich"
    },
    TEAM_1_TITLE: {
        ru: "...",
        uk: "CDM",
        en: "CDM"
    },
    TEAM_2_NAME: {
        ru: "...",
        uk: "Андрій Мілевський",
        en: "Andriy Milevsky"
    },
    TEAM_2_TITLE: {
        ru: "...",
        uk: "CEO",
        en: "CEO"
    },
    TEAM_3_NAME: {
        ru: "...",
        uk: "Аліна Рожанська",
        en: "Alina Rozhanska"
    },
    TEAM_3_TITLE: {
        ru: "...",
        uk: "CMO",
        en: "CMO"
    },
    WANT_TO_CONNECT_TO_TEAM: {
        ru: "...",
        uk: "Цікавить співпраця?",
        en: "Interested in cooperation?"
    },
    ANSWERS: {
        ru: "...",
        uk: "Відповіді на часті запитання",
        en: "Answers to frequently asked questions"
    },
    Q1: {
        ru: "...",
        uk: "Чому я повинен обрати вас",
        en: "Why should I choose you?"
    },
    Q1_VALUE: {
        ru: "...",
        uk: "Ми зібрали команду першокласних спеціалістів з великим творчим й професійним досвідом, які гарантують справді ексклюзивний дизайн інтер’єру та професійне виконання ваших забаганок.",
        en: "We have assembled a team of first-class professionals with extensive creative and professional experience, who guarantee truly exclusive interior design and professional execution of your desires."
    },
    Q2: {
        ru: "...",
        uk: "Як порахувати вартість ремонту",
        en: "How to calculate the cost of repairs"
    },
    Q2_VALUE: {
        ru: "...",
        uk: "Ми складаємо чіткі і прозорі кошториси та зрозумілі проєкти. Маємо надійних постачальників і партнерів, з якими співпрацюємо на протязі багатьох років.",
        en: "We create clear and transparent estimates and understandable projects. We have reliable suppliers and partners with whom we have been cooperating for many years."
    },
    Q3: {
        ru: "...",
        uk: "Чи можлива розстрочка?",
        en: "Is installment plan possible?"
    },
    Q3_VALUE: {
        ru: "...",
        uk: "Ми ділимо роботу на етапи і приймаємо оплату тільки після завершення етапу. Тому процес будівництва з нами це не удар по кишені, а плавний процес, з чітким розумінням що куди йде.",
        en: "We divide the work into stages and accept payment only upon completion of the stage. Therefore, the construction process with us is not a blow to the pocket, but a smooth process, with a clear understanding of what goes where."
    },
    Q4: {
        ru: "...",
        uk: "Що таке ремонт під ключ?",
        en: "What is a turnkey repair?"
    },
    Q4_VALUE: {
        ru: "...",
        uk: "Дана можливість включає в себе всі необхідні етапи: від створення проєкту до оздоблення приміщень. Замовник підключається тільки до узгодження кошторису і дизайн-проєкту. Замовнику немає необхідності розбиратися з рутинними і трудомісткими завданнями: вивезення сміття, закупівлею матеріалів, пошуком виконавців. Навіть розстановку меблів, підключення і налаштування техніки ми беремо на себе, попередньо узгодивши деталі.",
        en: "This option includes all the necessary stages: from creating a project to finishing the premises. The customer is involved only in the approval of the estimate and design project. The customer does not need to deal with routine and time-consuming tasks: garbage collection, purchase of materials, search for contractors. We even take care of the arrangement of furniture, connection and configuration of equipment, having previously agreed on the details."
    },
}


export default translates
