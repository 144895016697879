import api from '../service/apiClient'
import trans from '../helpers/translates'

export const addClass = ( classname, element ) => {
    var cn = element.className.split(" ");
    if( cn.indexOf( classname ) != -1 ) {
        return;
    }
    if( cn != '' ) {
        classname = ' '+classname;
    }
    element.className = cn+classname;
}
  
export const removeClass = ( classname, element ) => {
    var cn = element.className;
    var rxp = new RegExp( "\\s?\\b"+classname+"\\b", "g" );
    cn = cn.replace( rxp, '' );
    element.className = cn;
}
  
export const elemHasClass = (elem, cls) => {
    return new RegExp("( |^)" + cls + "( |$)").test(elem.className);
}

let onElement;

export const updateProperties = (elem, states, initial) => {
    const properties = {
        'x':      '--x',
        'y':      '--y',
        'width':  '--width',
        'height': '--height',
        'scale':  '--scale'
    };

    Object.keys(states).map((key) => {
        elem.style.setProperty( properties[key], states[key] + (key != 'scale' ? 'px': '' ))
    });

    if (initial) {
        onElement = null;
        eventOutline();
    }
}

export const eventOutline = () => {
    const arrayCursor = ['btn', 'l-btn', 'swiper-wrapper', 'swiper-slide--img'];
    
    document.querySelectorAll('.btn, .l-btn, .swiper-wrapper, .swiper-slide--img').forEach(elem => {
        elem.addEventListener('mouseenter', (e) => {
            arrayCursor.forEach(name => {
                if (elemHasClass(elem, name)) {
                    name == 'swiper-wrapper' || name == 'swiper-slide--img' ?
                        addClass('carousel', document.querySelector('.cursor')) : onElement = elem;
                }
            });
            return onElement
        });
        elem.addEventListener('mouseleave', () => {
            removeClass('carousel', document.querySelector('.cursor'));
            return onElement = undefined;
        });
    })
}

export const createStateCursor = e => {
    return;
    const defaultState = {
        x: e.clientX,
        y: e.clientY,
        width: 40,
        height: 40
    }
    const computedState = {}

    if (onElement != null) {
        const { top, left, width, height } = onElement.getBoundingClientRect()
        
        computedState.x = left + width / 2
        computedState.y = top + height / 2
        computedState.width = width
        computedState.height = height
    }

    return {
        ...defaultState,
        ...computedState
    }
}

export const initStateCursor = () => {
    return;

    let cursor = document.getElementById("cursor");
    if(cursor) {
        updateProperties(cursor, {
            width: 40,
            height: 40,
        }, true)
    
        document.addEventListener('mousemove', e => {
            const state = createStateCursor(e);
            updateProperties(cursor, state);
        });
        removeClass('carousel', document.querySelector('.cursor'))
    }
}

export const initStateAnimation = () => {
    let scrollTop = 0;
    let elements = document.querySelectorAll("[data-anim]");

    const initialState = () => {
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        
        elements.forEach((elem) => {
            if(window.innerHeight + scrollTop - 50 >= elem.offsetTop ) {
                elem.setAttribute("data-anim-used", true);
            }
        });
    }
    initialState();

    document.addEventListener('scroll', (e) => {
        initialState();
    });
}

export const getDateYear = (date) => {
    return new Date(date).getFullYear();
}


export const validationForm = (data) => {
    let validation = true;

    Object.entries(data).map( ([key, value]) => {
        if( !value.validation.status ) validation = false;
    })

    return validation;
}

export const random_item = (items) => items[Math.floor(Math.random()*items.length)];

const phases = [
    trans.PROMO_PHASE_1,
    trans.PROMO_PHASE_2,
    trans.PROMO_PHASE_3,
] 

export const random_phase = () => random_item(phases);


export const firstProjImage = (proj) => {
    return proj.photos[0] ? `${api.host}/images/${proj.photos[0]}` : "https://sitechecker.pro/wp-content/uploads/2017/12/404.png"
}

export const initConfigForSwiper = (item) => {
    return {
        slidesPerView: item.count,
        spaceBetween: item.spaceBetween,
        loop: false,
        observer: true, 
        observeParents: true,
        observeSlideChildren: true,
        autoHeight: item.height,
        navigation: { 
            nextEl: item.class + " .swiper-button-next", 
            prevEl: item.class + ' .swiper-button-prev'
        },
        breakpoints: {
            992: {
                slidesPerView: item.count,
                spaceBetween: item.spaceBetween
            },
            660: {
                slidesPerView: item.breakpoints ? 1 : item.count,
                spaceBetween: 10,
            },
            320: {
                slidesPerView: item.breakpoints ? 1 : item.count,
                spaceBetween: 10,
            }
        } 
    }
} 


export const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[key(x)] = rv[key(x)] || []).push(x);
      return rv;
    }, {});
  };