<script>
    import { fade, fly } from 'svelte/transition';
    import { order, message } from '../stores/stores';
    import { validationForm } from '../helpers/modules';
    import Input from './elements/Input.svelte';
    import api from '../service/apiClient';
    import trans from '../helpers/translates'
    import { language} from '../stores/stores'

    let isValid = true;
    $: inputs = {
        "name": {
            value: '',
            placeholder: trans.NAME[$language],
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "phone" : {
            value: '',
            placeholder: trans.PHONE[$language],
            require: "phone",
            type: 'mask',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "email": {
            value: '',
            placeholder: trans.EMAIL[$language],
            require: "email",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "comment": {
            value: '',
            placeholder: trans.YOUR_COMMENT[$language],
            require: "default",
            type: 'default',
            validation: {
                status: false,
                message: {
                    error: 'Поле заполнено неверно'
                }
            }
        },
        "checkbox": {
            value: false,
            placeholder: trans.ACCEPT_POLICY[$language],
            require: "checked",
            type: 'checkbox',
            validation: {
                status: false,
                message: {
                    error: 'Подтвердите политику конфеденциальности'
                }
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        isValid = validationForm(inputs);

        console.log(inputs);

        if( isValid ) {
            let name = inputs["name"].value;
            let phone = inputs["phone"].unmaskedValue;
            let email = inputs["email"].value;
            let comment = inputs["comment"].value;

            await api.makeApplication(name, phone, email, comment).then(response => {
                if(response.status == 200) {
                    message.update(v => v = true);
                    onClosed();
                }
            })
        }
    }

    const onClosed = () => {
        Object.entries(inputs).map(([key, val]) => {
            inputs[key].value = '';
            inputs[key].validation.status = false;
        })
        order.update(v => v = false);
    }
</script>

{#if $order}
<div class="ui-form" class:error-valid={!isValid} in:fly="{{ y: 200, duration: 500 }}" out:fade>
    <div class="container">
        <div class="ui-form--wrapper">
            <div class="title">
                {trans.SUBMIT_APPLICATION[$language]}
            </div>
            <form on:submit={onSubmit} autocomplete="off">
                <div class="ui-form--data row-flex">
                    <div class="col-md-6 col-xs-12 col">
                        <Input 
                            element={inputs["name"]} 
                            hanldeInput={(v) => { inputs["name"].value = v }} 
                        />
                    </div>
                    <div class="col-md-6 col-xs-12 col">
                        <Input 
                            element={inputs["phone"]} 
                            hanldeInput={(v) => { inputs["phone"].value = v }} 
                        />
                    </div>
                    <div class="col-md-6 col-xs-12 col">
                        <Input 
                            element={inputs["email"]} 
                            hanldeInput={(v) => { inputs["email"].value = v }} 
                        />
                    </div>
                    <div class="col-md-6 col-xs-12 col">
                        <Input 
                            element={inputs["comment"]} 
                            hanldeInput={(v) => { inputs["comment"].value = v }} 
                        />
                    </div>
                    <div class="col-md-6 col-xs-12 col">
                        <Input 
                            element={inputs["checkbox"]} 
                            hanldeInput={(v) => { inputs["checkbox"].value = v }} />
                    </div>
                </div>
                <div class="ui-form--navigate">
                    <button class="btn btn-new">{trans.SUBMIT_APPLICATION[$language]}</button>
                </div>
            </form>
            <div class="l-btn" on:click={onClosed}>
                <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                    <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
</div>
{/if}