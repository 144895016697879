<script>
  import { onMount } from 'svelte';
  import { links, navigate } from "svelte-routing";
  import { 
    initStateCursor,
    initConfigForSwiper,
    addClass, 
    firstProjImage,
    groupBy
  } from '../../helpers/modules';
  import { ripplesEffect } from '../../helpers/ripple';
  import waterEffect from '../../helpers/waterEffect';
  import Swiper from 'swiper';
  import Button from '../../components/Button.svelte';
  import Socials from '../../components/Socials.svelte';
  import api from '../../service/apiClient';
  import trans from '../../helpers/translates';
  import {language} from '../../stores/stores';


  let isExample = false;
  let projects = {};

  const getFirstItems = (data, length) => {
    return data.slice(0,length)
  }

  const initStatePage = () => {
    addClass('page-main', document.body);
    window.scrollTo(0, 0);
  }
  const initStateSwiper = () => {
    let sw = new Swiper('.examples .swiper-container', initConfigForSwiper({
      count: 'auto',
      height: false,
      class: '.examples',
      spaceBetween: "0",
      breakpoints: 1
    }));

    sw.on('slideChange', (e) => {
      
      if ( window.innerWidth <= 780 ) {
        let node = sw.slides[sw.realIndex]

        if (document.getElementById('canvasHome')) {
            let elem = document.getElementById('canvasHome');
            node.insertBefore(elem, node.children[0]);
        }
        setTimeout(()=> CanvasWaterEffect.moveSlider(sw.realIndex), 100);
      }
    });
  }

  

  const slideClick = () => {
    if ( window.innerWidth <= 780 ) {
      navigate("/projects")
    };
  }

  onMount(async () => {
    let data = Object.values(groupBy(await api.getAll(), x => x.type.ru));

    console.log(data);

    //hook for api all
    projects = data.map(x => x[0]);

    initStatePage();
    initStateSwiper();
    initStateCursor();

    waterEffect.init();
    waterEffect.update(projects);
  });
</script>

<div class="{isExample ? 'is-examples' : 'is-not-examples'}">
  
  <section class="main">
    <div class="container">
      <div class="wrapper">
        <h1>{trans.MAIN_PHASE[$language]}</h1>
        <br>
        <p>{trans.MAIN_PHASE_2[$language]}</p>
          
        <div class="navigate">
          <Button text="{trans.SUBMIT_APPLICATION[$language]}" type="order" />
          <Socials />
        </div>
      </div>
    </div>
  </section>
  
  <section class="examples">
    <div class="wrapper">
        <!-- Swiper --> 
        <div class="swiper-container">
            <div class="swiper-wrapper">
              {#each projects as item, key}
                <div class="swiper-slide" use:ripplesEffect={key} on:click={slideClick}>
                  <div class="swiper-slide--img"> 
                    <img src="{firstProjImage(item)}" alt="">
                  </div>
                  <div class="swiper-slide--content">
                    <h3>{item.type[$language]}</h3>
                  </div>
                  <div class="swiper-slide--navigate m-hide">
                    <a href="/projects" class="btn btn-preview" use:links>{trans.OUR_PROJECTS[$language]}</a>
                  </div>
                </div>
              {/each}
            </div>
        </div>
    </div>
    <!-- Add Arrows -->
    <div class="swiper-button">
      <div class="swiper-button-prev swiper-button-opacity">
        <div class="l-btn">
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
            <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>                      
      </div>
      <div class="swiper-button-next swiper-button-opacity">
        <div class="l-btn">
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
            <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>  
      </div>
    </div>
    <div class="target-group__btn">
      <div class="l-btn">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" on:click="{() => isExample = !isExample}">
          <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
          <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>   
    </div>
  </section>
</div>
<div id="canvasHome"></div>