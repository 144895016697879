<script>
  import { onMount } from 'svelte';
  import { links } from "svelte-routing";
  import { initStateCursor, initStateAnimation, removeClass, getDateYear, random_phase } from '../../helpers/modules';
  import NavLinkNext from '../../components/NavLinkNext.svelte';
  import Button from '../../components/Button.svelte';
  import Footer from '../../components/Footer.svelte';
  import api from '../../service/apiClient';
import { language } from '../../stores/stores';
import trans from '../../helpers/translates'

  export let id;

  let projectData = {
    title: {},
    description: {},
  };
  let projectNext = '';
  let projectGallery = [];
  let projectMembers = [];
  

  const initStatePage = () => {
    removeClass('page-main', document.body);
    window.scrollTo(0, 0);
  }
  const getStateClass = (length) => {
    return length == 1 ? 'full': '';
  }
  const getStateAnimation = (length, index) => {
    let animation = "fadeScale";
    if ( length > 1 ) {
      animation = index ? "fadeInRight" : "fadeInLeft";
    }
    return animation;
  }

  const imagePattern = (images) => { // [1,2,3,4,5, 6] -> [[1], [2,3], [4], [5, 6]]
    let take = 1
    let source = images
    let res = []

    while (source.length) {
      res.push(source.slice(0, take))
      source = source.slice(take) 
      take = take == 1 ? 2 : 1
    }

    return res
  }
  

  onMount( async() => {
    initStateCursor();
    initStatePage();

    await api.getProjInfo(id)
      .then(data => {
          projectData = data.project;
          projectNext = data.nextProject;
          projectMembers = data.project.members;
          projectGallery = imagePattern(data.project.photos);

          setTimeout(initStateAnimation, 100)
      });

    console.log(projectData);
  });
</script>

<section class="teamwork">
  <div class="container">
    <div class="row-flex">
      <div class="col-md-7 col-xs-12" data-anim="fadeInLeft" data-anim-used="false">
        <div class="teamwork-list relative">
          <h4>{trans.TEAM[$language]}:</h4>
          <ul>
            {#each projectMembers as name}
            <li>{name[$language]}</li>
            {/each}
          </ul>
          <a href="/projects" class="l-btn" use:links>
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
              <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="col-md-5 col-xs-12" data-anim="fadeInRight" data-anim-used="false">
        <h1>{projectData.title[$language]}</h1>
        <p>{projectData.description[$language]}
        </p>
        <div class="teamwork-measure">
          <span>{ projectData.square }</span> м²,
          <span>{ getDateYear(projectData.created) }</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gallery">
  <div class="proj-post-p mouse m-hide"><span></span></div>

  <div class="container">
    <div class="row-flex">
      {#each projectGallery as images}
        {#each images as item, i}
          <div class="gallery-item" data-anim={ getStateAnimation(images.length, i) } data-anim-used="false">
            <img src="{api.host}/images/{item}" alt="">
          </div>
        {/each}
      {/each}
    </div>
  </div>
</section>

{#if projectNext}
<section class="nextwork">

  <NavLinkNext path="/projects/{projectNext}" style="btn btn-more">
    {trans.NEXT_PROJECT[$language]}
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
      <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </NavLinkNext>
</section>
{/if}

<section class="request">
  <div class="container os-visible">
      <div class="wrapper">
          <div class="request-image">
              <img src="/images/request-image.png" alt="">
          </div>
          <div class="request-wrapper">
              <div class="request-title">
                {trans.LIKE_PROJECT[$language]}
              </div>
              <p>{random_phase()[$language]}</p>
              <br>
              <Button text="{trans.SUBMIT_APPLICATION[$language]}" type="order" />
          </div>
      </div>
  </div>
</section>


<Footer />