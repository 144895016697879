<script>
    import { onMount } from 'svelte';
    import { Link } from 'svelte-routing';
    import { 
        initStateCursor 
    } from '../../helpers/modules';
    import { 
        initDateFormat
    } from '../../helpers/dateFormat';
    import { 
        user,
        remove_comment
    } from '../../stores/stores';
    import api from '../../service/apiClient';
    import NavLink from '../../components/NavLink.svelte';
    import ButtonIcon from '../../components/ButtonIcon.svelte';

    let dataOrders = [];
    let targetElement = null;

    const initStatePage = () => {
        window.scrollTo(0, 0);
    }

    const handleTarget = (element) => {
        targetElement = element;
    }

    const handleUIRemoving = () => {
        dataOrders = dataOrders.filter(order => order.id != targetElement.id);

        targetElement = null;
        remove_comment.update(v => v = false);
    }

    const handleURLImage = (path) => {
        if(/(http(s?)):\/\//i.test(path) || !path) {
            return path
        }
        return api.host + '/images/' + path;
    }

    const unsubscribeRemove = remove_comment.subscribe(value => {
        if(value && targetElement) {
            api.removeComment(targetElement.id, $user)
                .then(result => {
                    if(result.status == 200) {
                        handleUIRemoving(targetElement);
                    }
                })
        }
	});

    onMount(async () => {
        initStatePage();
        initStateCursor();
        initDateFormat();

        await api.getCommentAll()
            .then(data => {
                dataOrders = data;
            });
    });
</script>

<section class="underwrite">
    <div class="underwrite-body">
        <nav class="underwrite-panel">
            <NavLink to="/admin/projects" class="icon icon-home">Проекты</NavLink>
            <NavLink to="/admin/comments" class="icon icon-home">Отзывы</NavLink>
        </nav>
        <div class="underwrite-content">
            <h2>Отзывы</h2>

            <div class="personal-orders">
                <div class="row-flex">
                    {#each dataOrders as order}
                    <div class="col col-md-4 col-xs-12 os-margin-20">
                        <div class="orders-item">
                            <div class="orders-item__head">
                                <img src={ handleURLImage(order.avatarUrl) } alt="">
                                <div>
                                    <div class="title">{ order.fullName }</div>
                                    <div class="date">{ new Date(order.created).format("dd.mm.yyyy") }</div>
                                </div>
                            </div>
                            <div class="orders-item__content">
                                <p>{ order.text }</p>
                            </div>
                            <div class="orders-item__navigate">
                                <Link class="l-btn btn-icon icon-edit" to="/admin/comments-edit/{order.id}"/>
                                <ButtonIcon type="order" method="remove" alt="Удалить" element={ order } handleCallback={ handleTarget } />
                            </div>
                        </div>
                    </div>
                    {/each}
                </div>
            </div>
            <div class="os-margin-20"></div>
            <Link class="btn btn-new" to="/admin/comments-edit/">Создать заявку</Link>
        </div>
    </div>
</section>

<div class="underwrite-navigate">
    <div>
        <Link to="/admin/projects" class="l-btn btn-icon icon-projects" />
        <p>Проекты</p>
    </div>
    <div>
        <Link to="/admin/comments" class="l-btn btn-icon icon-comments" />
        <p>Отзывы</p>
    </div>
</div>