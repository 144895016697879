<script>
    import { 
        user
    } from '../../stores/stores';
    import api from '../../service/apiClient';

    export let path;
    export let onDelete;
    export let onCallback = function(){};


    // const onReadURL = file => {
    //     return new Promise((res, rej) => {
    //         const reader = new FileReader();
    //         reader.onload = e => res(e.target.result);
    //         reader.onerror = e => rej(e);
    //         reader.readAsDataURL(file);
    //     });
    // };

    const handleURLImage = (path) => {
        if(/(http(s?)):\/\//i.test(path) || !path) {
            return path
        }
        return api.host + '/images/' + path;
    }

    const onPreview = async (event) => {
        const file = event.target.files[0];
        const fileData = new FormData();
            fileData.append('file', file);
              
        await api.uploadPhoto(fileData, $user)
            .then( response => {
                path = response[0];

                onCallback(path);
            }); 
    };
</script>

<div class="ui-input-image" class:is-load={!path}>
    <label>
        <input 
            type="file"
            on:change={onPreview}
        />
        <img src={handleURLImage(path)} alt="">
    </label>
    {#if onDelete && path}
        <span class="l-btn btn-icon icon-remove" on:click={ onDelete }></span>
    {/if}
</div>
