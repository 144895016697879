<script>
    import { onMount } from 'svelte';
    import { 
        initStateCursor,
        initStateAnimation,
        initConfigForSwiper,
        removeClass, 
    } from '../../helpers/modules';
    import { 
        initDateFormat
    } from '../../helpers/dateFormat';
    
    import api from '../../service/apiClient';
    import Button from '../../components/Button.svelte';
    import Accordion from '../../components/Accordion.svelte';
    import Footer from '../../components/Footer.svelte';
    import Swiper from 'swiper';
    import trans from '../../helpers/translates'
    import { language } from '../../stores/stores';

    let dataOrders = [];

    const initStatePage = () => {
        removeClass('page-main', document.body);
        window.scrollTo(0, 0);
    }

    const initStateSwiper = () => {
        new Swiper('.ourTeam .swiper-container', initConfigForSwiper({
            count: 3,
            height: false,
            class: '.ourTeam',
            spaceBetween: "10",
            breakpoints: true
        }));
        new Swiper('.orders .swiper-container', initConfigForSwiper({
            count: 'auto',
            height: false,
            class: '.orders',
            spaceBetween: "30",
            breakpoints: false
        }));
    }

    const handleURLImage = (path) => {
        if(/(http(s?)):\/\//i.test(path) || !path) {
            return path
        }
        return api.host + '/images/' + path;
    }

    onMount( async() => {
        initStateCursor();
        initStatePage();
        initStateAnimation();
        initStateSwiper();
        initDateFormat();

        await api.getCommentAll()
            .then(data => {
                dataOrders = data;
            });
    });
</script>

<div class="p-about mouse m-hide"><span></span></div>

<section class="about">
    <div class="container">
        <h1 data-anim="fadeInLeft" data-anim-used="false">{trans.ABOUT_TITLE[$language]}</h1>
        <img src="./images/about-00.png" alt="" data-anim="fadeInTop" data-anim-used="false">

        <p>{@html trans.ABOUT_US_VALUE[$language]}</p>
    </div>
</section>

<section class="ourTeam">
    <div class="container os-visible">
        <h1>{trans.OUR_TEAM[$language]}</h1>
        <div class="swiper-container os-visible">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="./images/ourTeam-02.png" alt="">
                    <div class="ourTeam-content">
                        <div class="title">
                            <span>{trans.TEAM_1_NAME[$language]}</span>
                        </div>
                        <div class="description">
                            <span>{trans.TEAM_1_TITLE[$language]}</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <img src="./images/ourTeam-00.png" alt="">
                    <div class="ourTeam-content">
                        <div class="title">
                            <span>{trans.TEAM_2_NAME[$language]}</span>
                        </div>
                        <div class="description">
                            <span>{trans.TEAM_2_TITLE[$language]}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="swiper-slide">
                    <img src="./images/ourTeam-01.png" alt="" class="fix-alina">
                    <div class="ourTeam-content">
                        <div class="title">
                            <span>{trans.TEAM_3_NAME[$language]}</span>
                        </div>
                        <div class="description">
                            <span>{trans.TEAM_3_TITLE[$language]}</span>
                        </div>
                    </div>
                </div> -->
            </div>
        
            <!-- Add Arrows -->
            <div class="swiper-button">
                <div class="swiper-button-prev swiper-button-opacity">
                    <div class="l-btn">
                        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                        <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>                      
                </div>
                <div class="swiper-button-next swiper-button-opacity">
                    <div class="l-btn">
                        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                        <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ourAds">
    <div class="container">
        <div class="wrapper">
            <h3>{trans.WANT_TO_CONNECT_TO_TEAM[$language]}</h3>
            <Button text="{trans.SUBMIT_APPLICATION[$language]}" type="order" />
            <div class="ourAds-image"></div>
        </div>
    </div>
</section>

<!-- 
    <section class="orders">
    <div class="container os-visible">
        <div class="wrapper">
            <div class="swiper-header">
                <h1>Отзывы клиентов</h1>
                <div class="swiper-button">
                    <div class="swiper-button-prev">
                        <div class="l-btn">
                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                            <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>                      
                    </div>
                    <div class="swiper-button-next">
                        <div class="l-btn">
                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="35" cy="35" r="35" transform="rotate(-90 35 35)" fill="white"/>
                            <path d="M32.2007 43.4248L40.6007 35.0248L32.2007 26.6248" stroke="#003366" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="swiper-container os-visible">
                <div class="swiper-wrapper">
                    {#each dataOrders as order}
                        <div class="swiper-slide orders-item">
                            <div class="orders-item__head">
                                <img src="{ handleURLImage(order.avatarUrl) }" alt="">
                                <div>
                                    <div class="title">{ order.fullName }</div>
                                    <div class="date">{ new Date(order.created).format("dd.mm.yyyy") }</div>
                                </div>
                            </div>
                            <p>{ order.text }</p>
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</section> 
-->

<!-- <div class="os-text-center">
    <Button text="Оставить отзыв" type="comment" verification="true" />
</div> -->

<section class="faq">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <h1>{trans.ANSWERS[$language]}</h1>
            </div>
            <div class="col-md-6 col-sm-12">
                <Accordion title="{trans.Q1[$language]}">
                    <p>
                        {@html trans.Q1_VALUE[$language]}
                    </p>
                </Accordion>
                <Accordion title="{trans.Q2[$language]}">
                    <p>
                        {@html trans.Q2_VALUE[$language]}
                    </p>
                </Accordion>
                <Accordion title="{trans.Q3[$language]}">
                    <p>
                        {@html trans.Q3_VALUE[$language]}
                    </p>
                </Accordion>
                <Accordion title="{trans.Q4[$language]}">
                    <p>
                        {@html trans.Q4_VALUE[$language]}
                    </p>
                </Accordion>
            </div>
        </div>
    </div>
</section>

<Footer />
