<script>
    import { confirm, remove_comment, remove_project } from '../stores/stores.js';
    import { fade, fly } from 'svelte/transition';

    const handleClose = () => {
        confirm.update(v => v = {
            status: false,
            type: null
        });
    }

    const handleClick = (value) => {
        switch ($confirm.type) {
            case 'order':
                remove_comment.update(v => v = value)
                break;
            case 'project':
                remove_project.update(v => v = value)
                break;
        }
        handleClose();
    }
</script>

{#if $confirm.status}
<div class="popup-message" in:fly="{{ y: 200, duration: 500 }}" out:fade>
    <p>Вы уверены, что хотите удалить ?</p>
    <div class="os-justify-flex">
        <button class="btn" on:click={ () => { handleClick(true) } }>Удалить</button>
        <button class="btn" on:click={ () => { handleClick(false) } }>Отменить</button>
    </div>
</div>
<div class="popup-overlay"></div>
{/if}