<script>
    export let text = '';
    export let type = '';

    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';
    import { order, comment } from '../stores/stores';
    import storage from '../service/storageClient';

    const handleClick = () => {
        switch (type) {
            case 'order':
                order.update(v => v = true);
                break;
            case 'comment':
                comment.update(v => v = true)
                break;
        }
        navigate('?popup');
    }

    onMount(async () => {
        storage.init();

        window.onpopstate = function() {
            order.update(v => v = false);
            comment.update(v => v = false);
        };
    });
</script>

<button class="btn btn-new" on:click="{ handleClick }">{ text }</button>