<script>
  import { Router, Route } from "svelte-routing";
  import { onMount } from "svelte";
  import { loading, language } from "./stores/stores";
  import storage from "./service/storageClient";

  //component
  import Loading from "./components/Loading.svelte";
  import Header from "./components/Header.svelte";
  import PopupForm from "./components/PopupForm.svelte";
  import PopupMessage from "./components/PopupMessage.svelte";
  import PopupComment from "./components/PopupComment.svelte";
  import PopupConfirm from "./components/PopupConfirm.svelte";
  //guard
  import PrivateRoute from "./guards/PrivateRoute.svelte";
  //client
  import Home from "./feature/client/Home.svelte";
  import Projects from "./feature/client/Projects.svelte";
  import ProjectsPost from "./feature/client/ProjectsPost.svelte";
  import About from "./feature/client/About.svelte";
  import Contacts from "./feature/client/Contacts.svelte";
  //Admin
  import AdminHome from "./feature/admin/Home.svelte";
  import AdminProjects from "./feature/admin/Projects.svelte";
  import AdminComments from "./feature/admin/Comments.svelte";
  import CommentsEdit from "./feature/admin/CommentsEdit.svelte";
  import ProjectsEdit from "./feature/admin/ProjectsEdit.svelte";

  import { initDateFormat } from "./helpers/dateFormat";
  import googleOneTap from "google-one-tap";
  import { auth } from "./service/authClient";
  import we from './helpers/modification'


  // Used for SSR. A falsy value is ignored by the Router.
  export let url = "";

  onMount(async () => {
    we.initial();
    language.set(localStorage.getItem("language") || "uk");
    language.subscribe((lang) => localStorage.setItem("language", lang));
    storage.init();
    initDateFormat();

    googleOneTap({
      client_id: "764635261523-7ot9o5lmi4q0asbcveo0lf0ee49app2r.apps.googleusercontent.com",
      auto_select: false,
    }, async (response) => {
      console.log(response);
      await auth(response.credential);
    });
  });
</script>

<Loading />
<Header />

{#if !$loading}
  <Router {url}>
    <Route path="/" component={Home} />
    <Route path="contacts" component={Contacts} />
    <Route path="projects" component={Projects} />
    <Route path="projects/:id" component={ProjectsPost} />
    <Route path="about" component={About} />
    <Route path="admin" component={AdminHome} />

    <PrivateRoute path="admin/comments">
      <AdminComments />
    </PrivateRoute>
    <PrivateRoute path="admin/comments-edit">
      <CommentsEdit />
    </PrivateRoute>
    <PrivateRoute path="admin/comments-edit/:id" let:params>
      <CommentsEdit bounce={params.id} />
    </PrivateRoute>

    <PrivateRoute path="admin/projects">
      <AdminProjects />
    </PrivateRoute>
    <PrivateRoute path="admin/projects-edit">
      <ProjectsEdit />
    </PrivateRoute>
    <PrivateRoute path="admin/projects-edit/:id" let:params>
      <ProjectsEdit bounce={params.id} />
    </PrivateRoute>
  </Router>
{/if}

<PopupForm />
<PopupMessage />
<PopupComment />
<PopupConfirm />

<!-- <div id="cursor" class="cursor"></div> -->
<style global src="./assets/sass/main.sass"></style>
