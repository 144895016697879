<script>
    import { onMount } from 'svelte';
    import { emailValidator, phoneValidator, checkedValidator, requiredValidator } from '../../helpers/validators';
    import { createFieldValidator } from '../../helpers/validation';
    import { imask } from 'svelte-imask';
    
    export let element = {
        value: '',
        placeholder: "Поле",
        require: "default",
        type: 'default',
        validation: {
            status: false,
            message: {
                error: 'Поле заполнено неверно'
            }
        }
    };
    export let isHandler = false;
    export let hanldeInput = null;

    const validators = {
        'phone': [requiredValidator, phoneValidator],
        'default': [requiredValidator],
        'email': [requiredValidator, emailValidator],
        'checked': [checkedValidator]
    }

    const initialMask = {
        options: {
            mask: '+{38}(000)000-00-00',
            lazy: false,
            placeholderChar: '_'
        },
        initialUnmaskedValue: ''
    }

    const [ validity, validate ] = createFieldValidator( ...validators[element.require].map( func => func() ) )

    const setHandler = (state) => {
        isHandler = Boolean(state);
    }
    const onInput = (e) => {
        if(hanldeInput) {
            hanldeInput(e.target.value)
            return;
        } 
        element.value = e.target.value;
    }
    const onInputDate = (e) => {
        element.value = new Date(e.target.value).toISOString();
    }
    const onInputMask = ({ detail: imask }) => {
        element.value = imask.maskedValue;
        element.unmaskedValue = imask.unmaskedValue;
    }
    const onChecked = () => {
        element.value = !element.value;
    }
	const onFocus = (e)  => {
        setHandler(true);
    }
	const onBlur =  ()  => {
        init();
    }
    const init = () => {
        setHandler(element.value.length)
    }
    onMount(async () => {
        init();
    })
</script>


<!-- ============ checkbox ============= -->
{#if element.type == "checkbox"}
    <label 
        class="ui-checkbox"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
    >
        <input 
            type="checkbox"
            value={element.value}
            on:change={onChecked} 
            use:validate={element.value}
        />
        <span>{ element.placeholder }</span>
        <div class="ui-input--error">
            { element.validation.message.error }
        </div>
    </label>

<!-- ============ select ============= -->
{:else if element.type == "selected"}
    <label 
        class="ui-input"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
        class:field-active={$validity.valid || isHandler}
    >
        <span>{ element.placeholder }</span>
        <select 
            value={element.value} 
            on:input={ onInput }
            use:validate={element.value}>
            <option value="loft">Лофт</option>
            <option value="hi-tech">Hi-tech</option>
            <option value="modern">Модерн</option>
            <option value="test">Другие</option>
        <select>

    </label>

<!-- ============ date ============= -->
{:else if element.type == "date"}
    <label 
        class="ui-input"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
        class:field-active={$validity.valid || isHandler}
    >
        <span>{ element.placeholder }</span>
        <input type="date" 
            value={new Date(element.value).format("yyyy-mm-dd")}
            on:input={ onInputDate }
            use:validate={element.value}
        />
    </label>

<!-- ============ textarea ============= -->
{:else if element.type == "textarea"}
    <label 
        class="ui-input"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
        class:field-active={$validity.valid || isHandler}
    >
        <textarea
            value={element.value}
            on:input={onInput} 
            on:focus={onFocus} 
            on:blur={onBlur} 
            use:validate={element.value}
        ></textarea>

        <span>{ element.placeholder }</span>

        <div class="ui-input--error">
            { element.validation.message.error }
        </div>
    </label>

<!-- ============ mask ============= -->
{:else if element.type == "mask"}
    <label 
        class="ui-input field-active"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
    >
        <span>{ element.placeholder }</span>
        <input 
            type="hidden" 
            value={element.value}
            autocomplete="false"
            use:validate={element.unmaskedValue}
        />
        <input 
            use:imask={initialMask.options} 
            value={initialMask.initialUnmaskedValue}
            name="phone" 
            type="tel" 
            on:accept={onInputMask}
        />
        <div class="ui-input--error">
            { element.validation.message.error }
        </div>
    </label>

<!-- ============ input  ============= -->
{:else}
    <label 
        class="ui-input"
        class:field-danger={!$validity.valid}
        class:field-success={$validity.valid}
        class:field-active={$validity.valid || isHandler}
    >
        <span>{ element.placeholder }</span>
        <input 
            type="text"
            value={element.value}
            on:input={onInput}
            on:change={onInput} 
            on:focus={onFocus} 
            on:blur={onBlur} 
            use:validate={element.value}
        />
        <div class="ui-input--error">
            { element.validation.message.error }
        </div>
    </label>
{/if}

<div style="display: none">
    { element.validation.status = $validity.valid }
</div>